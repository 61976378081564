import TargetCard from "@/components/TargetCard";
import { getFitColor } from "@/utils";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FIT_OPTIONS } from "@/fragments/Constants";

const DEFAULT_FILTER_MODEL = { items: [] };
const DEFAULT_SORT_MODEL = [];

const SORT_FIELDS = [
  "domain",
  "updated",
  "employees",
  "fit",
  "source",
  "rating",
];

export default function TargetCardContainer({ targets, searchData }) {
  const [filterModel, setFilterModel] = useState(DEFAULT_FILTER_MODEL);
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODEL);
  const [fitFilter, setFitFilter] = useState("All");
  const [sortField, setSortField] = useState("updated");
  const [sortDirection, setSortDirection] = useState("desc");

  const currentDirection = sortModel[0]?.sort;

  useEffect(() => {
    try {
      // const savedFilter = JSON.parse(localStorage.getItem("filterModel"));
      // if (savedFilter?.items) setFilterModel(savedFilter);

      const savedSort = JSON.parse(localStorage.getItem("sortModel"));
      if (Array.isArray(savedSort) && savedSort.length) {
        setSortModel(savedSort);
        setSortField(savedSort[0].field);
        setSortDirection(savedSort[0].sort);
      }
    } catch (err) {
      console.error("Parse error:", err);
    }
  }, []);

  // Update sortModel whenever user changes the field/direction
  useEffect(() => {
    if (sortField) {
      const newSortModel = [{ field: sortField, sort: sortDirection }];
      setSortModel(newSortModel);
      localStorage.setItem("sortModel", JSON.stringify(newSortModel));
    }
  }, [sortField, sortDirection]);

  console.log("filterModel", filterModel);
  // Filter
  const filteredTargets = targets?.filter((target) =>
    filterModel.items.every(({ field, operator, value }) => {
      const fieldVal = String(target[field] ?? "").toLowerCase();
      const checkVal = value?.toLowerCase() || "";
      switch (operator) {
        case "equals":
          return fieldVal === checkVal;
        case "notEquals":
          return fieldVal !== checkVal;
        case "contains":
          return fieldVal.includes(checkVal);
        case "startsWith":
          return fieldVal.startsWith(checkVal);
        case "endsWith":
          return fieldVal.endsWith(checkVal);
        default:
          return true;
      }
    })
  );

  // Sort
  const sortedTargets = filteredTargets?.slice().sort((a, b) => {
    for (const { field, sort } of sortModel) {
      if (field === "employees" || field === "updated") {
        const valA = parseInt(a[field]) || 0;
        const valB = parseInt(b[field]) || 0;
        if (valA < valB) return sort === "asc" ? -1 : 1;
        if (valA > valB) return sort === "asc" ? 1 : -1;
      } else {
        const valA = String(a[field] ?? "").toLowerCase();
        const valB = String(b[field] ?? "").toLowerCase();
        if (valA < valB) return sort === "asc" ? -1 : 1;
        if (valA > valB) return sort === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  // Fit counts
  const getFitCount = (fit) =>
    fit === "All"
      ? sortedTargets?.length ?? 0
      : sortedTargets?.filter((t) => t.fit === fit).length;

  // Filter by fit
  const finalTargets =
    fitFilter === "All"
      ? sortedTargets
      : sortedTargets?.filter((t) => t.fit === fitFilter);

  const toggleSortDirection = () => {
    setSortModel(([{ field, sort }]) => [
      { field, sort: sort === "asc" ? "desc" : "asc" },
    ]);
  };

  return (
    <Box sx={{ width: "calc(100vw - 240px)", pb: 20, mt: 1 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1, gap: 2 }}>
          <Select
            label="Sort Field"
            variant="standard"
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            {SORT_FIELDS.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>

          <IconButton onClick={toggleSortDirection} size="small">
            {currentDirection === "asc" ? <ArrowUpward /> : <ArrowDownward />}
          </IconButton>
        </Box>

        <Stack direction="row" spacing={2}>
          <ToggleButtonGroup exclusive size="small">
            {FIT_OPTIONS.map((fit) => {
              const count = getFitCount(fit);
              if (count <= 0) return null;
              return (
                <ToggleButton
                  key={fit}
                  value={fit}
                  selected={fitFilter === fit}
                  onClick={() => setFitFilter(fit)}
                  color={getFitColor(fit)}
                  sx={{ borderRadius: "30px" }}
                >
                  {fit} ({count})
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Stack>
      </Stack>

      {/* Show Targets */}
      {finalTargets?.slice(0, 50).map((target) => (
        <TargetCard
          key={target.domain}
          target={target}
          searchData={searchData}
        />
      ))}
    </Box>
  );
}
