import axios from "axios";
import React, { useEffect, useState } from "react";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import { Box, Skeleton, Typography, Link } from "@mui/material";
import { DataUsage, Whatshot } from "@mui/icons-material";

import { API_BASE } from "@/App.js";
import { LABEL_MAP } from "@/fragments/Constants.js";

export default function ReportingSearches({}) {
  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearches = async (e) => {
    try {
      setLoading(true);
      const endpoint = API_BASE + "/data/searches";
      const response = await axios.get(endpoint, {
        params: {
          actor_key: localStorage.getItem("actorKey"),
          token: localStorage.getItem("accessToken"),
        },
      });
      setSearches(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(async () => {
    getSearches();
  }, []);

  const indexColumns = [
    {
      field: "analyst",
      width: 150,
    },
    {
      field: "search",
      width: 180,
      renderCell: (params) => (
        <Link
          href={`/search?searchKey=${encodeURIComponent(
            params.row.search_uid
          )}&currentView=land`}
          style={{
            cursor: "pointer",
            textDecoration: "none",
          }}
          color="info"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "status",
      type: "string",
      width: 120,
    },
  ];

  const stageColumns = Object.entries(LABEL_MAP).map(([key, label]) => ({
    field: key,
    headerName: label,
    type: "number",
    // Set the width to 140 if the key is "send", otherwise use 120
    width: key === "send" ? 140 : 100,
    cellClassName: (params) => {
      if (params.value == 0) {
        return "number-is-zero";
      }
    },
    // Add the "auto--header" class for the "send" column
    headerClassName: key === "send" ? "auto--header" : undefined,
  }));

  const columns = [...indexColumns, ...stageColumns];

  return (
    <Box
      sx={{
        my: 4,
        "& .number-is-zero": {
          color: "#4E4B4B",
        },
        "& .auto--header": {
          color: "#04FFFF",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <DataUsage color="auto" sx={{ fontSize: 24 }} />
        <Typography variant="h5" sx={{ my: 2 }}>
          Search Volumes
        </Typography>
      </Box>

      {/* {searches.length > 0 && <SankeyDiagram searches={searches} />} */}

      {loading && (
        <Skeleton
          variant="rectangular"
          height={360}
          style={{ borderRadius: "5px" }}
        />
      )}

      {!loading && (
        <DataGridPremium
          rows={searches}
          columns={columns}
          getRowId={(row) => row.search}
          density="compact"
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50, 100]}
          cellSelection
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },
            "& .MuiDataGrid-footerCell": {
              color: "auto.main",
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: { sortModel: [{ field: "send", sort: "desc" }] },
            // rowGrouping: { model: ["analyst"] },
            // filter: {
            //   filterModel: {
            //     items: [
            //       {
            //         field: "status",
            //         operator: "equals",
            //         value: "active",
            //       },
            //     ],
            //   },
            // },
            columns: {
              columnVisibilityModel: {
                status: false,
                // analyst: false,
              },
            },
            aggregation: {
              model: Object.keys(LABEL_MAP).reduce((acc, label) => {
                acc[label] = "sum";
                return acc;
              }, {}),
            },
          }}
        />
      )}
    </Box>
  );
}
