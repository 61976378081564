import React, { useState } from "react";
import { AutoAwesome, Hub } from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enrichTarget } from "@/services/enrich";

export default function EnrichTargetButton({ target }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const searchUid = target.search_uid;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(() => enrichTarget(target), {
    onSuccess: (data) => {
      setSnackbarMsg(data.message || "Target enriched");
      setSnackbarOpen(true);
      queryClient.invalidateQueries(["targets", Number(searchUid)]);
      queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
      queryClient.invalidateQueries(["domainStageMap", searchUid]);
    },
    onError: (err) => console.error(err),
  });

  return (
    <>
      <Tooltip title="Enrich + Check Fit" placement="top">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton onClick={() => mutate()} disabled={isLoading}>
            <AutoAwesome color="auto" />
          </IconButton>
        )}
      </Tooltip>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          color="primary"
          variant="filled"
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
