import {
  ArrowBackIos,
  IosShare,
  SpeakerNotes,
  Launch,
  TableView,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchDomains from "@/components/SearchDomains";
import { LABEL_MAP } from "@/fragments/Constants";
import { useSearchData } from "@/services/hooks";
import { getIcon } from "@/utils";
import NextResultsButton from "./NextResultsButton";
import { DEALCLOUD_ENGAGEMENT_BASE } from "@/fragments/Constants";

function DisplayToggle({ displayMode, setDisplayMode }) {
  useEffect(() => {
    localStorage.setItem("displayMode", displayMode);
  }, [displayMode]);

  const handleDisplayChange = (_, newMode) => {
    if (newMode !== null) {
      setDisplayMode(newMode);
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="caption" color="textSecondary">
        Display Mode
      </Typography>
      <ToggleButtonGroup
        value={displayMode}
        exclusive
        onChange={handleDisplayChange}
        size="small"
        aria-label="display mode"
      >
        <ToggleButton value="grid" aria-label="grid view">
          <TableView color={displayMode === "grid" ? "info" : "inherit"} />
        </ToggleButton>
        <ToggleButton value="card" aria-label="list view">
          <SpeakerNotes color={displayMode === "card" ? "info" : "inherit"} />
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="caption" color="info">
        {displayMode === "grid" ? "Grid" : "Notes"} View
      </Typography>
    </Stack>
  );
}

export function ShareButton({ searchUid }) {
  const [open, setOpen] = useState(false);

  const handleShare = () => {
    const url = DEALCLOUD_ENGAGEMENT_BASE + searchUid;
    navigator.clipboard.writeText(url);
    setOpen(true);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Tooltip title="Copy URL to clipboard" placement="top">
        <IconButton onClick={handleShare} color="primary" aria-label="share">
          <IosShare />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setOpen(false)} color="primary" variant="filled">
          URL copied to clipboard!
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export function OpenDealcloud({ searchUid }) {
  const openInDealcloud = () => {
    const url = DEALCLOUD_ENGAGEMENT_BASE + searchUid;
    window.open(url, "_blank");
  };

  return (
    <Box>
      <Tooltip title="Open Dealcloud" placement="top">
        <IconButton onClick={openInDealcloud}>
          <Launch sx={{ fontSize: "20px", mx: 1, opacity: 0.7 }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default function PageAppBar({ displayMode, setDisplayMode }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchKey = searchParams.get("searchKey");
  const currentView = searchParams.get("currentView");

  const { data: searchData } = useSearchData({ uid: searchKey });

  return (
    <AppBar variant="transparent" position="sticky">
      <Toolbar
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          pl: 0,
          gap: 10,
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <IconButton onClick={() => navigate("/")} aria-label="back to search">
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {searchData?.label}
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mt: 0.5, mr: 4 }}
          >
            {getIcon(currentView, "info")}
            <Typography variant="caption" color="textSecondary">
              {LABEL_MAP[currentView]}
            </Typography>
          </Stack>

          <ShareButton searchUid={searchKey} />
          <OpenDealcloud searchUid={searchKey} />
        </Box>

        <DisplayToggle
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
        />

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            display: {
              md: "none",
              lg: "flex",
            },
          }}
        >
          <SearchDomains searchUid={searchKey} />
          <NextResultsButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
