import { API_BASE } from "@/App";

export const enrichTarget = async (target) => {
  const enrich_base = API_BASE.replace("api", "enrich");
  try {
    const response = await fetch(`${enrich_base}/enrich`, {
      method: "POST",
      body: JSON.stringify({
        search_uid: target.search_uid,
        domain: target.domain,
        actor_key: localStorage.getItem("actorKey"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error enriching target:", error);
    throw error;
  }
};
