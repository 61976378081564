import React from "react";

import { Box, Typography } from "@mui/material";

export default function Footer({}) {
  const releaseNotes =
    "https://parker-holcomb.notion.site/G-AI-Release-Notes-72b781d654c447ada716949ce5422bcd";

  return (
    <Box
      id="footer"
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "50px 0",
        color: "gray",
        width: "100%",
      }}
    >
      <Typography variant="comment">
        A
        <a
          href="https://www.genzassociates.com/"
          target="_blank"
          style={{ color: "gray", margin: "0 5px" }}
        >
          Genz & Associates
        </a>
        Company, © {new Date().getFullYear()}
      </Typography>
      <Typography>
        You are using the{" "}
        <a target="_blank" href={releaseNotes} style={{ color: "gray" }}>
          {" "}
          January 20th Release
        </a>
      </Typography>
    </Box>
  );
}
