import { DEALCLOUD_ENGAGEMENT_BASE } from "@/fragments/Constants";
import { getWeekColor } from "@/utils";
import { fromNow } from "@/utils/time";
import { Launch } from "@mui/icons-material";
import { Box, Chip, Link } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";

const openInDealcloud = (row) => {
  const url = DEALCLOUD_ENGAGEMENT_BASE + row.uid;
  window.open(url, "_blank");
};

export const getColumns = () => [
  {
    field: "analyst",
    headerName: "Analyst",
    minWidth: 120,
    valueGetter: (value) => value?.name,
  },
  {
    field: "lead",
    headerName: "Deal Lead",
    minWidth: 120,
    valueGetter: (value) => value?.name,
  },
  {
    field: "week",
    type: "singleSelect",
    valueOptions: ["A", "B"],
    renderCell: ({ row }) =>
      row.week && (
        <Chip label={row.week} size="small" color={getWeekColor(row.week)} />
      ),
  },
  {
    field: "day",
    type: "singleSelect",
    valueOptions: ["1-Mon.", "2-Tue.", "3-Wed.", "4-Thu.", "5-Fri."],
  },
  {
    field: "status",
    width: 120,
  },
  {
    field: "label",
    headerName: "search",
    width: 220,
    renderCell: ({ row }) => (
      <Link
        href={`/search?searchKey=${row.uid}&currentView=land`}
        style={{ textDecoration: "none", color: "#04FFFF" }}
      >
        {row.label}
      </Link>
    ),
  },
  {
    field: "type",
  },
  {
    field: "business_type",
  },
  {
    field: "products",
    flex: 1,
  },
  {
    field: "services",
    flex: 1,
  },
  {
    field: "end_market",
    flex: 1,
  },
  {
    field: "geographies",
    flex: 1,
  },
  {
    field: "size",
    // editable: true,
    width: 80,
  },
  {
    field: "notes",
    flex: 1,
  },
  {
    field: "updated",
    width: 100,
    type: "number",
    valueGetter: (value) => {
      return typeof value === "number" ? value : Date.now() / 1000;
    },
    valueFormatter: (value) => fromNow(value),
  },
  {
    field: "created",
    headerName: "Age",
    width: 100,
    type: "number",
    valueGetter: (value) => {
      return typeof value === "number" ? value : Date.now() / 1000;
    },
    valueFormatter: (value) => fromNow(value),
  },
  {
    field: "actions",
    type: "actions",
    headerName: "",
    width: 80,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<Launch />}
        label="Open in Dealcloud"
        onClick={() => openInDealcloud(params.row)}
        showInMenu
      />,
    ],
  },
];
