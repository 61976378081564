import CommentsContainer from "@/components/CommentsContainer";
import EnrichTargetButton from "@/components/EnrichTargetButton";
import GetSimiliarButton from "@/components/GetSimilarButton";
import RatingContainer from "@/components/RatingContainer";
import { getFitColor } from "@/utils";
import { AutoAwesome, JoinInner, Launch, LinkedIn } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import Highlighter from "react-highlight-words";
import ChipRow from "./ChipRow";
import EventButtonsContainer from "./EventButtonsContainer";
import { DEALCLOUD_COMPANY_BASE } from "@/fragments/Constants";
import JSONPretty from "react-json-pretty";

export default function TargetCard({ searchData, target }) {
  const isLimited = () => {
    return false;
    // return ["create", "land", "similar"].includes(target.stage);
  };

  return (
    <Card
      variant="outlined"
      key={target.domain}
      sx={{ p: 4, my: 4, backgroundColor: "rgba(36,36,36,0.7)" }}
    >
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {!isLimited() && (
            <>
              <Link
                href={`https://www.${target.domain}`}
                target="_blank"
                color="info"
                sx={{ mr: 2 }}
              >
                {target.domain}
              </Link>
              {target["linkedin"] && (
                <IconButton
                  href={target["linkedin"]}
                  target="_blank"
                  // sx={{ p: 0 }}
                >
                  <LinkedIn sx={{ fontSize: "20px", mx: 1, opacity: 0.7 }} />
                </IconButton>
              )}
              {target["company_uid"] && (
                <Tooltip title="Open Dealcloud" placement="top">
                  <IconButton
                    // href={`${}${target["company_uid"]}`}
                    href={DEALCLOUD_COMPANY_BASE + target["company_uid"]}
                    target="_blank"
                  >
                    <Launch sx={{ fontSize: "20px", mx: 1, opacity: 0.7 }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}

          <Typography variant="h5" sx={{ fontWeight: 600, mr: 2 }}>
            {target.name}
          </Typography>
        </Box>

        <Box sx={{ mx: 2 }}>
          <EnrichTargetButton target={target} />
        </Box>

        <Box>
          <GetSimiliarButton target={target} />
        </Box>

        <EventButtonsContainer target={target} />
      </Box>

      {!target.gpt_description && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 8 }}>
          <CircularProgress />
        </Box>
      )}

      {target.gpt_description && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <ChipRow searchData={searchData} target={target} />
          <TargetDescription target={target} />
          <FitCheck target={target} />

          <WasAcquired target={target} />
          <RatingContainer target={target} />
        </Box>
      )}

      <CommentsContainer searchData={searchData} target={target} />
    </Card>
  );
}

function FitCheck({ target }) {
  if (!target.fit) {
    return null;
  }

  return (
    <Box
      id="fit-description"
      style={{
        wordWrap: "break-word",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <JoinInner
        sx={{ fontSize: "1.2em", mr: 1, mt: 0.2 }}
        color={getFitColor(target.fit)}
      />
      <Typography
        variant="body"
        sx={{
          flexShrink: 0, // This prevents the element from shrinking
        }}
      >
        {target.fit}
      </Typography>

      <Box sx={{ ml: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="body">{target.fit_reason}</Typography>

        {/* {target.fit_questions && (
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
            {Object.entries(target.fit_questions).map(([key, value]) => (
              <Typography variant="body" key={key}>
                {key}: {value}
              </Typography>
            ))}
          </Box>
        )} */}
        {/* {JSON.stringify(target.fit_questions, null, 2)} */}
        {/* <JSONPretty data={target.fit_questions} style={{ fontSize: "0.7em" }} /> */}
      </Box>
    </Box>
  );
}

function TargetDescription({ target }) {
  return (
    <Box id="target-description" style={{ wordWrap: "break-word" }}>
      <AutoAwesome sx={{ fontSize: "1.2em", mr: 1 }} color="info" />
      <Highlighter
        // searchWords={keywords || []}
        searchWords={[]}
        autoEscape={true}
        textToHighlight={target.description || ""}
      />
    </Box>
  );
}

function WasAcquired({ target }) {
  if (target.was_acquired != "Yes") {
    return null;
  }

  return (
    <>
      {target.meta.was_acquired != "" && (
        <Box style={{ width: "100%" }}>
          <Typography variant="caption" color="lightgray">
            was_acquired
          </Typography>
          <Typography variant="body" style={{ marginLeft: "15px" }}>
            {target.meta.was_acquired}
          </Typography>
          <Typography variant="caption" style={{ marginLeft: "15px" }}>
            {target.meta.justification}
          </Typography>
        </Box>
      )}
    </>
  );
}
